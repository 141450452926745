.preview-page {
  .preview-iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding-top: 60px;
  }

  .preview-toast {
    position: absolute;
    margin-top: $spacing-sm;
    right: $spacing-md;
    z-index: 1;
    max-width: 400px;
  }
}
