.modal--syndication {
  cursor: auto;
  .modal--dialog {
    padding-bottom: em(20px);
  }

  .toast--syndication {
    margin-top: em($spacing-sm);
    margin-bottom: 0;
  }

  .toast--syndication a {
    text-shadow: inherit;
    color: inherit;
    background-image: unset;
    text-decoration: underline;
    border-bottom: none;
  }

  .rights-information {
    padding: 0 em($spacing-md);
  }

  .copilot-link {
    @include link-clean();
  }
}
