.search {
  border-radius: get-spacing(border-radius);
  border: 1px solid get-color(gray, light);

  .search-input--wrapper {
    z-index: 0;
    border: none;
  }

  button {
    font-weight: 600;
  }
}
