.buttonwithdropdown {
  display: inline;
  position: relative;
}

.buttonwithdropdown--button {
  line-height: $spacing-xlg;
  position: relative;
  margin-right: $spacing-xs;
  padding: $spacing-xs $spacing-xxlg $spacing-xs $spacing-md;
  text-transform: capitalize;
  border: 1px solid get-color(gray, light);
  font-weight: 500;

  svg {
    @include transition(transform);
    position: absolute;
    right: 8px;
    top: 11px;
    fill: currentColor;
  }
  &[aria-expanded='true'] svg {
    transform: rotate(180deg);
  }
}

.buttonwithdropdown--dropdown {
  position: absolute;
  width: auto;
  left: 0;
  margin-top: 6px;
  box-shadow: 0 0 2px rgba(get-color(black), 0.3);
  border-radius: get-spacing(border-radius);
  background-color: get-color(white);

  z-index: get-z(toggled-menu);

  ul {
    @include navigation-list();
    li {
      @include nav-item();
      cursor: pointer;
      white-space: pre;
      &:hover {
        background-color: get-color(gray, x-light);
      }
    }
  }
}

.destructibletag {
  @include badge();
  @include common-color(black);

  align-items: center;
  cursor: pointer;
  display: inline-flex;
  line-height: 1;
  margin-right: $spacing-xxs;
  margin-bottom: $spacing-xxs;

  svg {
    fill: currentColor;
    height: $spacing-xs;
    margin-left: 5px;
  }
}

.market-filters {
  @include actions--toggle;
  right: initial;
  display: block;
  cursor: pointer;
}
