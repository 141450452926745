@import '~@condenast/formation-styleguide/src/styles/style';
@import './styles/discovery';
@import './styles/slat';
@import './styles/buttonwithdropdown';
@import './styles/search';
@import './styles/filter__list-item';
@import './styles/modals/syndicationModal';
@import './styles/modals/syndicationPolicyModal';
@import './styles/pages/previewPage';
@import './styles/nav';
html {
  font-size: 100%;
}

.page-container {
  overflow: unset;
}
