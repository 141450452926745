.top-nav {
  height: 10px;
  background-color: get-color(white);
  z-index: 999;
  border-color: get-color(gray, light);
  align-items: center;

  .nav-header--text {
    letter-spacing: 1px;
    font-family: 'Libre Franklin', Helvetica, Arial, sans-serif;
    margin-top: $spacing-xxs;
    font-size: em(20px);
    color: get-color(black);
  }

  .top-nav__right-side-section {
    display: flex;
    align-items: center;

    & > * {
      margin: auto $spacing-xs;
    }
  }
}

.preview-page .top-nav {
  flex-direction: column;
  padding: $spacing-sm;
  height: 10px;

  @include breakpoint($bp--medium) {
    flex-direction: row;
  }

  .top-nav__page-title {
    text-transform: uppercase;
    margin: $spacing-xxs $spacing-sm 0;
    font-size: em(14px);
    font-weight: bold;
    width: 85%;
  }
}

.tooltip {
  color: get-color(white);
  background: get-color(black);
  width: max-content;
  position: absolute;
  z-index: 999;
  border-radius: 2px;
  padding: 4px 8px;
  transform: translate(-38%, 18%);
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.tooltip:after {
  content: ' ';
  position: absolute;
  right: calc(100% - 54%);
  top: -3px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid get-color(black);
}

.copy-button {
  position: relative;
}

.copy-button .button--icon:focus {
  outline: none;
  box-shadow: none;
}

.tooltip-content-copied {
  padding: 4px 9px 4px 6px;
  transform: translate(-32%, 18%);
}

.help-dropdown {
  position: relative;
  left: 4rem;
  top: 78px;
  z-index: 3;
}

.help-dropdown > ul {
  background: #ffffff;
  padding: 0.5rem 0;
  list-style: none;
  box-shadow: 0 0 8px rgba(43, 58, 68, 0.4);
  width: 243px;
  border-radius: 4px;
}

.help-dropdown > ul > li {
  .help-menu-dropdown,
  a {
    display: inline-block;
    border-bottom: 0;
    padding: 8px 16px;
    width: 100%;
    text-decoration: none;
    line-height: 20px;
    color: #171717;

    &:hover {
      background: #f3f3f3;
    }
  }
}
