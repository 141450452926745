@import 'variables';

.discovery {
  max-width: 880px;
  margin: $spacing-xxlg auto;

  > :not(:last-child) {
    margin-top: $spacing-xxlg;
  }
  .navigation-wrapper {
    display: flex;
    ul {
      li {
        display: flex;
        align-items: flex-end;
      }
    }
    .link-chrome-extension {
      height: 55px;
      justify-content: flex-end;
      border-bottom: unset;
    }
  }
}

.discovery-filters {
  border-radius: get-spacing(border-radius);
  background-color: get-color(white);
  padding: $spacing-lg;
}

.discovery--active-filter {
  display: inline-block;
  margin-right: $spacing-md;
  text-transform: capitalize;
}

.discovery-filters__available {
  display: flex;
  flex-direction: column;

  @include breakpoint($bp--large) {
    flex-direction: row;
  }
}

.discovery-syndicatable-toggle {
  display: flex;
  align-items: center;
  font-weight: 800;
  position: relative;
  @include breakpoint($bp--large) {
    margin-left: auto;
  }
}

.discovery-filters input[type='checkbox'].checkbox--switch {
  position: absolute;
}

.discovery-filters input[type='checkbox'] + label {
  font-weight: bold;
}
