.slat {
  @include breakpoint($bp--large) {
    height: 112px;
  }

  display: flex;
  align-items: center;
  color: inherit;
  padding-right: 1.875em;

  .slat--title {
    font-weight: 500;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .disabled {
    color: get-color(gray);
    font-weight: 600;

    a {
      color: get-color(gray);
    }
  }

  .slat--actions {
    button {
      border-color: get-color(gray, light);
      font-weight: 600;
    }
  }
}

.slatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.slat--brand-market-navigation-wrapper {
  display: flex;
  align-items: center;
}

.brand-market-name {
  font-weight: bold;
  text-transform: uppercase;
}

.slat--feature-translantion {
  font-weight: 500;
  color: #3953e7;
  text-transform: uppercase;
}

.smallMarginLeft {
  margin-left: $spacing-xs;
}

.thumbnail {
  @include breakpoint($bp--large) {
    height: 100%;
  }
}

.slatDetails {
  margin: 0;
  color: get-color(black);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
