// Vertical rhythm is super important to enforce consistency regarding
// spacing and having numbers that allow us to do this is a good thing.
// Shame it's not from Formation, but here we're using the
// "4:5 Major Third" ratio: https://www.modularscale.com/?1&em&1.25

$spacing-xxs: 0.262rem;
$spacing-xs: 0.512rem;
$spacing-sm: 0.8rem;
$spacing-md: 1rem;
$spacing-lg: 1.25rem;
$spacing-xlg: 1.563rem;
$spacing-xxlg: 1.953rem;
